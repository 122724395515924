import { Component, OnDestroy, TemplateRef } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { Subscription } from 'rxjs'
import { Hive } from 'src/app/_models'
import { AuthenticationService, HeaderService, ModalService, NavigationService } from 'src/app/_services'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { HiveAPIService } from 'src/app/_api-services'

@Component({
    selector: 'hives',
    templateUrl: './hive-hub.component.html',
    styleUrls: ['./hive-hub.component.scss']
})
export class HiveHubComponent implements OnDestroy {
    private subscriptions: Subscription = new Subscription()
    showCreateHive: boolean = false
    showForm: FormGroup | null = null

    hives: Hive[] = []
    userIsLoggedIn: boolean = false
    hivesExpanded: boolean = false
    userHives: Hive[] = []

    constructor(
        private hiveAPIService: HiveAPIService,
        private titleService: Title,
        private metaService: Meta,
        private headerService: HeaderService,
        private modalService: ModalService,
        private authService: AuthenticationService,
        private router: Router,
        private navService: NavigationService,
    ) {
        this.titleService.setTitle('Hives | Hub Hive')
        this.headerService.setHeaderState(true, false)
        this.userIsLoggedIn = this.authService.isLoggedIn()

        this.subscriptions.add(
            this.hiveAPIService.getUserHives().subscribe({
                next: hives => {
                    this.userHives = hives
                },
                error: err => {
                    console.log(err)
                }
            })
        )

        if (this.userIsLoggedIn) {
            this.subscriptions.add(
                this.hiveAPIService.getHivesUserNotIn().subscribe({
                    next: hives => {
                        this.hives = hives
                    },
                    error: err => {
                        console.log(err)
                    }
                })
            )
        } else {
            this.subscriptions.add(
                this.hiveAPIService.list().subscribe({
                    next: (hives) => {
                        this.hives = hives
                        this.metaService.updateTag({ name: 'description', content: 'Hub Hive Connect offers ways to connect with communities unlike any other social media application.' })
                    },
                    error: (err) => {
                        console.log(err)
                    }
                })
            )
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
        this.modalService.close()
    }

    add(content: TemplateRef<any>): void {
        this.navService.navigateToAppStore()
        return;
        if (!this.userIsLoggedIn) {
            this.router.navigate(['/register'], { queryParams: { redirect: this.router.url } })
            return
        }
        this.modalService.open('Add new hive', content, this.hives)
        this.showCreateHive = true
    }

    revealHives() {
        this.hivesExpanded = true
    }
}
